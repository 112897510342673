$(document).ready(function(){
  window.show_notification = function(message, message_type='info' , timeout=12000){

    createToast(message,message_type,timeout)

}
});


function createToast(text,message_type,timeout) {
  let id = new Date().getTime();
  let html = `<div class="position-absolute w-100 d-flex flex-column align-items-end" style="margin-top: 6px;" >
              <div class="toast align-items-center text-white border-0 ${get_message_class(message_type)}" role="alert" aria-live="assertive" aria-atomic="true" style="z-index:1" id=${id}  data-bs-autohide="true">
                <div class="d-flex rm">
                  <div class="toast-body">
                    ${text}
                  </div>
                  <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
              </div>
            </div>`;

  document.getElementById('toast-container').innerHTML += html;


  $(`#${id}`).toast('show');

  $(`#${id}`).delay(timeout).fadeOut(500, function(){
    $(`#${id}`).toast('hide');
});
}

function get_message_class(message_type){
  if(message_type == 'success'){
    return 'bg-success';
  } else if(message_type == 'danger' || message_type == "error") {
    return 'bg-danger';
  } else if(message_type == 'notice') {
    return 'bg-warning';
  } else {
    return 'bg-info';
  }
}
