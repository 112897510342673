$(document).ready(function (argument) {
$('.alert').addClass('alert-dismissible d-flex mb-0 fade show');
$('.close').addClass('btn-close');
$('.close').empty();
});


$(document).ready(function (argument) {
$(".alert-success").delay(2000).fadeOut(500, function(){
    $(".alert-success").alert('close');
});
});
