function copyToClipboard(text) {

    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}

$(document).ready(function(){
$(document).on('click','#copy_button',function(){

  var id = $(this).attr("value")
  var result = copyToClipboard($("#inputforpassword_" + id).val());

})


$(document).on('click','#copy_to_clipboard_spf',function(){
  var result = copyToClipboard($("#spf_value_id").val());
})

$(document).on('click','#copy_to_clipboard_spf_key',function(){
  var result = copyToClipboard($("#spf_key_id").val());
});


$(document).on('click','#copy_to_clipboard_dkim',function(){
  var result = copyToClipboard($("#dkim_value_id").val());
})

$(document).on('click','#copy_to_clipboard_dkim_key',function(){
  var result = copyToClipboard($("#dkim_key_id").val());
});

});
