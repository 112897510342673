import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("Dis Connected");
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    if( data.job_status == "completed") {
      $('#'+data.job_id+" .status").html("<i class='fa fa-check'></i> "+ data.job_status)

      if (data.actions == true || data.actions == "true"){
         $('#'+data.job_id+" .password a").removeClass('d-none');
      }
    }
    else {
      $('#'+data.job_id+" .status").text(data.job_status)
    }

    if (data.message.includes("Failed")){
      show_notification(data.message, 'danger')
    } else{
      show_notification(data.message, 'success')
      if (data['tab'] !== null) {
       if (data['action_type']) {
        $.ajax({
            url: "/admin/dns/hosted_zones/update_section",
            method: "GET",
            data: { tab: data['tab'], user_domain_id: data['user_domain_id'] },
          });

        // $.get('/update_sections?action_type='+data['action_type']+'&user_domain_id='+data['user_domain_id'], function(html){

        // })
      }
    }
  }
  }
});
